// main: ../master.scss

// The dark themed sidebar for the dashboard
.TopBar {
  width: 100%;
  height: 100px;
  background: $whiteColor;

  .TopBarContent {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    padding: 30px 80px;

    .TopBarLogo {
      @include pushable();

      img {
        height: 41px;
      }
    }
    .TopBarLinks {
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      align-items: center;

      .TopBarLink {
        margin: 0px 0px 0px 50px;
        @include pushable();
        @include fontSizeNormal();
        color: $grayColor1;
      }
      .TopBarLink {
        padding: 0px;
      }
      .TopBarLinkActive {
        font-weight: 600;
        color: $darkColor;
      }
      .TopBarLogin {
        background: $darkColor;
        padding: 10px 40px;
        border-radius: 20px;
        color: $whiteColor;
        @include fontSizeNormal();
        &:hover {
          @include boxShadow();
        }
      }
      .TopBarProfile {
        background: $whiteColor;
        padding: 0px 20px;
        border-radius: 20px;
        @include boxShadowLight();
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: center;

        &:hover {
          @include boxShadow();
        }

        .TopBarProfileInfo {
          padding: 5px 0px 3px 0px;
          .TopBarProfileName {
            color: $darkColor;
            @include fontSizeNormal();
          }
          .TopBarProfileAccountType {
            color: $grayColor1;
            @include fontSizeTiny();
            margin-top: -2px;
          }
        }

        img {
          margin-left: -10px;
          max-height: 20px;
          width: 20px;
          height: 20px;
          object-fit: cover;
          margin-right: 6px;
          border-radius: 10px;
          @include fadeIn();
        }
      }
    }
  }
  .TopBarMiniContent {
    display: none;
  }
}

$menuLineHeight: 2px;
$menuLineWidth: 24px;

@media screen and (max-width: $breakSmall) {
  .TopBar {
    width: 100%;
    height: 48px;
    background: $whiteColor;

    .TopBarContent {
      display: none;
    }
    .TopBarMiniContent {
      width: 100%;
      height: 48px;
      position: fixed;
      z-index: 10000;
      display: block;
      background: rgba(0,0,0,0.8);
      -webkit-backdrop-filter: saturate(180%) blur(20px);
      backdrop-filter: saturate(180%) blur(20px);
      color: $whiteColor;
      transition: all .5s ease-in-out;

      .TopBarMiniContentTop {
        width: 100%;
        height: 48px;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;

        .TopBarMenu {
          padding: 0px 0px 0px 12px;
          width: 24px;
          @include pushable();

          .menu-btn {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 33px;
            height: 15px;
            transition: all .5s ease-in-out;
          }
          .menu-btn__burger {
            width: $menuLineWidth;
            height: $menuLineHeight;
            background: $whiteColor;
            border-radius: $menuLineHeight / 2;
            transition: all .5s ease-in-out;
          }
          .menu-btn__burger::before,
          .menu-btn__burger::after {
            content: '';
            position: absolute;
            width: $menuLineWidth;
            height: $menuLineHeight;
            background: $whiteColor;
            border-radius: $menuLineHeight / 2;
            transition: all .5s ease-in-out;
          }
          .menu-btn__burger::before {
            transform: translateY(-8px);
          }
          .menu-btn__burger::after {
            transform: translateY(8px);
          }

          /* ANIMATION */
          .menu-btn.open .menu-btn__burger {
            transform: translateX(-50px);
            background: transparent;
            box-shadow: none;
          }
          .menu-btn.open .menu-btn__burger::before {
            transform: rotate(45deg) translate(35px, -35px);
          }
          .menu-btn.open .menu-btn__burger::after {
            transform: rotate(-45deg) translate(35px, 35px);
          }
        }
        .TopBarLogo {
          @include pushable();
          img {
            height: 30px;
          }
        }
        .TopBarSpace {
          padding: 0px 12px 0px 0px;
          width: 24px;
          height: 10px;
          //provides spacing to center the Floww Icon in the middle of the screen.
        }
      }
      .TopBarMiniContentContent {
        width: 100%;
        height: 100px;
        @include fadeIn();

        hr {
          margin: 0px 0px 15px 0px;
          border-color: $grayColor1;
        }

        .TopBarMiniContentLink {
          display: inline-block;
          text-align: left;
          padding: 15px 12px;
          @include fontSizeMedium();
          @include pushable();
        }
      }
    }
    .TopBarMiniContentExpanded {
      height: 100%;
      background: $darkColor;
    }
  }
}
