// main: ../master.scss
@keyframes candymove {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 50px 50px;
  }
}

// The dark themed sidebar for the dashboard
.Profile {
  @include fadeIn();
  background: $whiteColor;
  min-height: calc(100% - 100px);

  .ZoomPrompt {
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: transparentize($whiteColor, 0.02);
    @include fadeIn();
    text-align: center;

    .ZoomPromptTitle {
      @include fontSizeTitle();
      padding-top: 100px;
    }
    .ZoomPromptDescription {
      @include fontSizeNormal();
      padding: 20px 10px;
    }
    .ZoomPromptLink {
      @include fontSizeNormal();
    }
  }

  .ProfileTab {
    background: $offWhiteColor;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    padding: 10px $mainPaddingSize;

    .ProfileTabItem {
      @include pushable();
      padding: 0px 20px;
      color: $grayColor1;
    }
    .ProfileTabItemActive {
      color: $darkColor;
      font-weight: bold;
    }
  }
  .ProfileEditBar {
    background: $grayColor2;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: center;
    padding: 0px 0px 0px $mainPaddingSize;

    .ProfileEditBarItem {
      @include pushable();
      padding: 10px 20px;
      color: $grayColor3;
      border: 1px solid $grayColor4;
      border-top: 0px;
      border-bottom: 0px;
      transition: 0.5s all;

      img {
        max-height: 20px;
        margin-left: 8px;
      }

      &:hover {
        background: $grayColor5;
        color: $whiteColor;
      }
    }
    .ProfileEditBarItemDot {
      display: inline-block;
      width: 8px;
      height: 8px;
      background: none;
      border-radius: 4px;
      margin-right: 8px;
      margin-bottom: 1px;
    }
    .ProfileEditBarItemDotActive {
      background: $actionColor;
    }
    .ProfileEditBarItemActive {
      color: $actionColor;
    }
    .ProfileEditBarItem2 {
      border-left: 0px;
    }
  }
  .FormBlock {
    padding: 50px $mainPaddingSize;

    .ProfileFormTitle {
      @include fontSizeTitle();
      padding-bottom: 25px;
    }
    .ProfileFormName {
      @include fontSizeStat();
      color: $grayColor1;
      padding-bottom: 25px;
    }
    .ProfileFormData {
      @include fontSizeNormal();
      color: $darkColor;
      padding-bottom: 5px;
    }
  }
  .ClassList {
    padding: 50px $mainPaddingSize;
    background: $whiteColor;

    .ClassListLoader {
      text-align: center;
      margin: 0px auto;
      width: 100%;
    }
    .ClassListEmpty {
      .ClassListEmptyTitle {
        text-align: center;
        @include fontSizeNormal();
        color: $darkColor;
        margin-top: -30px;
      }
      .ClassListEmptyButton {
        text-align: center;
        padding-top: 20px;
      }
    }
    .ClassListTitle {
      @include fontSizeTitle();
      color: $darkColor;
      text-align: center;
      padding-bottom: 40px;
    }
    .ClassListClass {
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      align-items: center;
      padding: 10px 0px;

      .ClassListClassVideo {
        flex-basis: 50%;
        .ClassListClassVideoContainer {
          width: 100% !important;
          max-width: 600px;
          max-height: 340px;
        }
      }
      .ClassListClassContent {
        padding: 0px 0px 0px 25px;
        text-align: center;
        flex-basis: 50%;
        .ClassListClassContentTitle {
          @include fontSizeTitle();
        }
        .ClassListClassContentDescription {
          padding: 5px 0px;
          @include fontSizeNormal();
        }
        .ClassListClassContentDate {
          @include fontSizeNormal();
          padding-bottom: 20px;
        }
      }
    }
  }
  .InstructorProfile {
    // Top Section
    .InstructorProfileTop {
      position: relative;
      width: 100%;
      height: 600px;

      .InstructorProfileBackground {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0px;
        top: 0px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .InstructorPhotoUpload {
        position: absolute;
      }
      .InstructorProfileContent {
        position: absolute;
      }
    }
    // Text Position Options
    .InstructorProfileTopAngleTopLeft {
      .InstructorPhotoUpload {
        left: calc(50% - 90px);
        bottom: 100px;
      }
      .InstructorProfileContent {
        top: 50px;
        left: 15%;
      }
    }
    .InstructorProfileTopAngleTopRight {
      .InstructorPhotoUpload {
        left: calc(50% - 90px);
        bottom: 100px;
      }
      .InstructorProfileContent {
        top: 50px;
        right: 15%;
      }
    }
    .InstructorProfileTopAngleBottomLeft {
      .InstructorPhotoUpload {
        left: calc(50% - 90px);
        top: 100px;
      }
      .InstructorProfileContent {
        bottom: 50px;
        left: 15%;
      }
    }
    .InstructorProfileTopAngleBottomRight {
      .InstructorPhotoUpload {
        left: calc(50% - 90px);
        top: 100px;
      }
      .InstructorProfileContent {
        bottom: 50px;
        right: 15%;
      }
    }

    // Instructor About Section
    .InstructorProfileAbout {
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      align-items: stretch;

      .InstructorProfileAboutLeft {
        flex-basis: 50%;
        padding: 50px 0px 50px $breakPaddingSize;
        .BoxedInput {
          .inputBottomLineLight {
            color: $darkColor;
          }
          .inputBottomAreaLight {
            color: $darkColor;
          }
        }
        .InstructorProfileAboutLeftTitle {
          @include fontSizeTitle();
          color: $darkColor;
        }
        .InstructorProfileAboutLeftContent {

        }
      }
      .InstructorProfileAboutRight {
        position: relative;
        flex-basis: 50%;
        min-height: 500px;

        .InstructorProfileAboutRightImage {
          position: absolute;
          z-index: 10;
          left: 0px;
          top: 0px;
          width: 100%;
          height: 100%;
          object-fit: cover;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .InstructorProfileAboutRightContent {
          position: absolute;
          left: calc(50% - 90px);
          bottom: 100px;
          z-index: 11;
        }
      }
    }
  }
  // Live Section
  .InstructorProfileLive {
    padding: 50px $breakPaddingSize;
    text-align: center;

    .InstructorProfileLiveTitle {
      @include fontSizeTitle();
      color: $darkColor;
      text-align: center;
      padding-bottom: 15px;
    }
    .InstructorProfileLiveSchedule {
      text-align: center;
      padding-bottom: 15px;
    }
    .InstructorProfileLivePrevious {
      @include fontSizeNormal();
      font-weight: bold;
      color: $themeColor1;
      padding-top: 10px;
      @include pushable();
      display: inline-block;
    }
  }
  // On Demand Section
  .InstructorProfileOnDemand {
    padding: 50px $breakPaddingSize;
    text-align: center;

    .InstructorProfileOnDemandTitle {
      @include fontSizeTitle();
      color: $darkColor;
      text-align: center;
      padding-bottom: 15px;
    }
    .InstructorProfileOnDemandSchedule {
      text-align: center;
      padding-bottom: 15px;
    }
  }
  // List of Instructor Classes
  .InstructorProfileClasses {
    .InstructorProfileClass {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      align-items: center;
      @include fadeIn();

      .InstructorProfileClassLeft {
        padding: $breakPaddingSize;
        flex-basis: 50%;
        max-width: 100%;
        text-align: center;
        position: relative;

        .inputBottomLineLightContainer {
          position: absolute;
          z-index: 100;
          left: calc(50% - 90px);
          bottom: 50px;
          width: auto;

          .FileSelectorDiv {
            padding: 0px;
          }
          .inputBottomLineInvalid {
            display: none;
          }
        }

        .InstructorProfileClassLeftImage {
          width: 100%;
          height: 100%;
          max-height: 450px;
          object-fit: cover;
        }

        .InstructorProfileClassLeftButton {
          position: absolute;
          z-index: 100;
          left: calc(50% - 90px);
          bottom: 50px;
        }
      }
      .InstructorProfileClassRight {
        padding: $breakPaddingSize;
        flex-basis: 50%;
        text-align: center;

        .InstructorProfileClassRightContentZoomStartLink {
          padding-top: 15px;
          @include fontSizeMedium();
          color: $themeColor2;
          text-decoration: underline;
          @include pushable();
          display: inline-block;
        }
        .InstructorProfileClassRightContentPurchaseCount {
          @include fontSizeNormal();
          font-weight: bold;
          color: $grayColor0;
          padding-top: 10px;
        }
        .InstructorProfileClassRightContentPurchaseCountColored {
          color: $themeColor1;
          text-decoration: underline;
          @include pushable();
          display: inline-block;
        }
        .PriceFormBlockOptions {
          padding: 10px 0px;
        }

        .BoxedInput {
          .inputBottomLineLight {
            color: $darkColor;
            text-align: center;
          }
          .inputBottomAreaLight {
            color: $darkColor;
            text-align: center;
          }
        }
      }
    }

    .react-datepicker-wrapper {
      .react-datepicker__input-container {
        input {
          text-align: center;
          border-bottom: 0px;

          &:focus {
            border-bottom: 0px;
          }
        }
      }
    }
  }

  .VideoUploadDiv {
    text-align: center;
    width: 100%;
    height: 450px;
    overflow: hidden;
    position: relative;
    transition: 300ms all;

    .VideoUploadDivBackground {
      width: 100%;
      height: 100%;
      object-fit: cover;
      background: $offWhiteColor;
      border-radius: 22px;
      border: 1px dashed $grayColor0;
      transition: all 300ms;
    }

    .VideoUploadDivInner {
      display: flex;
      flex-flow: column wrap;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      transition: 300ms all;

      .VideoUploadDivInnerContent {
        width: 100%;
        text-align: center;
        margin: 0px auto;
      }

      .VideoUploadDivInnerUpImage {
        object-fit: contain;
        padding: 5px;
        border-radius: 50px;
        transition: 300ms all;

        .UploadAnimationLottie {
          width: 100px;
          height: 100px;
        }
      }
      .VideoUploadDivInnerDescription {
        @include fontSizeNormal();
        color: $darkColor;
        transition: 300ms all;
        padding-bottom: 15px;
      }
      .VideoUploadDivInput {
        width: 0.1px;
      	height: 0.1px;
      	opacity: 0;
      	overflow: hidden;
      	position: absolute;
      	z-index: -1;
        transition: 300ms all;
      }
      .FormBlockButtonTheme {
        padding: 0px;
        transition: 300ms all;
      }
      .VideoUploadDivInputLabel {
        cursor: pointer;
        font-weight: normal;
        width: 180px;
        height: 50px;
        padding-top: 15px;
        transition: 300ms all;
      }

      .VideoUploadDivInnerProgressBar {
        width: calc(100% - 64px);
        height: 25px;
        background: lighten($themeColor1, 25%);
        margin: 0px auto;
        text-align: left;
        border-radius: 13px;
        overflow: hidden;

        div {
          height: 100%;
          transition: 300ms all;
          overflow: hidden;
          position: relative;

          background: $themeColor1;
          background-image: linear-gradient(to bottom, $themeColor1, darken($themeColor1, 10%));
        }
        div:after, div > span {
          content: "";
          position: absolute;
          top: 0; left: 0; bottom: 0; right: 0;
          background-image: linear-gradient(
            -45deg,
            rgba(255, 255, 255, .2) 25%,
            transparent 25%,
            transparent 50%,
            rgba(255, 255, 255, .2) 50%,
            rgba(255, 255, 255, .2) 75%,
            transparent 75%,
            transparent
          );
          z-index: 1;
          background-size: 50px 50px;
          animation: candymove 2s linear infinite;
          border-top-right-radius: 8px;
          border-bottom-right-radius: 8px;
          border-top-left-radius: 20px;
          border-bottom-left-radius: 20px;
          overflow: hidden;
        }
      }
      .VideoUploadDivInnerProgressLabel {
        @include fontSizeNormal();
        color: $darkColor;
        padding-top: 15px;
      }
    }
  }
  .VideoUploadDivHover {
    .VideoUploadDivBackground {
      background: lighten($themeColor1, 30%);
      border: 1px dashed $themeColor1;
    }
    .VideoUploadDivInner {
      .VideoUploadDivInnerDescription {
        opacity: 0%;
      }
      .VideoUploadDivInnerUpImage {
      }
      .FormBlockButtonTheme {
        opacity: 0%;
      }
      .VideoUploadDivInputLabel {
        opacity: 0%;
      }
    }
  }

  .ClassPurchases {
    text-align: center;
    padding: 10px $mainPaddingSize;

    .ClassPurchasesContent {
      .ClassPurchasesContentTitle {
        @include fontSizeTitle();
        color: $darkColor;
      }
      .ClassPurchasesContentDescription {
        @include fontSizeMedium();
        color: $grayColor1;
        padding-bottom: 20px;
      }
      .ClassPurchaseRow {
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-start;
        align-items: center;
        padding: 10px 0px;
        border-top: 1px solid $offWhiteColor2;

        .ClassPurchaseRowPhoto {
          width: 100px;
          height: 100px;
          border-radius: 50px;
          overflow: hidden;
          margin: 10px 20px 10px 0px;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .ClassPurchaseRowInfo {
          display: flex;
          flex-flow: column nowrap;
          justify-content: center;
          align-items: flex-start;
          width: 300px;
          margin: 10px 20px 10px 0px;

          .ClassPurchaseRowName {
            @include fontSizeMedium();
            color: $darkColor;
          }
          .ClassPurchaseRowEmail {
            @include fontSizeNormal();
            color: $grayColor1;
          }
        }

        .ClassPurchaseRowStatus {
          @include fontSizeNormal();
          color: $themeColor1;
          padding: 5px;
          width: 100px;
          background: lighten($themeColor1, 30%);
          border-radius: 8px;
          margin-right: 20px;
        }

        .ClassPurchaseRowPrice {
          @include fontSizeNormal();
          color: $themeColor2;
          padding: 5px;
          width: 60px;
          background: lighten($themeColor2, 30%);
          border-radius: 8px;
          margin-right: 20px;
        }
        .ClassPurchaseRowPriceFree {
          color: $grayColor1;
          background: $offWhiteColor;
        }
        .ClassPurchaseRowDate {
          @include fontSizeNormal();
          color: $grayColor1;
        }
      }
    }
  }

  .InstructorPayouts {
    text-align: center;
    padding: 10px $mainPaddingSize;

    .InstructorPayoutsContent {
      .InstructorPayoutsContentTitle {
        @include fontSizeTitle();
        color: $darkColor;
        padding-top: 40px;
        padding-bottom: 10px;
      }
      .InstructorPayoutsContentDescription {
        @include fontSizeNormal();
        color: $darkColor;
        padding-bottom: 20px;
      }
      .InstructorPayoutsContentBalances {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-around;
        align-items: flex-end;

        .InstructorPayoutsContentBalance {
          display: flex;
          flex-flow: column nowrap;
          justify-content: center;
          align-items: flex-start;
          padding: 20px 0px;
          //flex-basis: 50%;

          .InstructorPayoutsContentBalanceTitle {
            @include fontSizeMedium();
            font-weight: bold;
            color: $darkColor;
          }
          .InstructorPayoutsContentBalanceDescription {
            @include fontSizeNormal();
            color: $darkColor;
            text-align: left;
            max-width: 300px;
            padding: 10px 0px 0px 0px;
          }
          .InstructorPayoutsContentBalanceValue {
            @include fontSizeHuge();
            color: $themeColor1;
          }
          .InstructorPayoutsContentBalanceValueAlt {
            color: $grayColor0;
          }
        }
      }

      .InstructorPayoutsMethods {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-around;
        align-items: center;

        .InstructorPayoutsMethod {
          display: flex;
          flex-flow: column nowrap;
          justify-content: center;
          align-items: center;
          padding: 20px 0px;
          //flex-basis: 50%;

          .InstructorPayoutsMethodTitle {
            @include fontSizeTitle();
            font-weight: bold;
            color: $darkColor;
          }
          .InstructorPayoutsMethodDescription {
            @include fontSizeNormal();
            color: $darkColor;
            padding: 20px 0px;
          }
          .InstructorPayoutsMethodID {
            @include fontSizeNormal();
            color: $darkColor;
            padding-top: 10px;
          }
          .InstructorPayoutsMethodIDValue {
            color: $grayColor1;
            font-weight: bold;
          }
          .InstructorPayoutsMethodPayPalButton {
            color: $whiteColor;
            background: $payPalColor;
            width: 220px;
            padding: 15px 0px;
            @include pushable();
            margin: 0px auto;
            border-radius: 4px;

            img {
              margin-right: 16px;
            }
          }
          .InstructorPayoutsMethodVenmoButton {
            color: $whiteColor;
            background: $venmoColor;
            width: 220px;
            padding: 15px 0px;
            @include pushable();
            margin: 0px auto;
            border-radius: 4px;

            img {
              margin-right: 16px;
            }
          }
        }
      }

      .InstructorPayoutsList {
        .InstructorPayoutsListTitle {
          @include fontSizeTitle();
          color: $darkColor;
          padding-top: 40px;
          padding-bottom: 10px;
        }
        .InstructorPayoutsListDescription {
          @include fontSizeNormal();
          color: $darkColor;
          padding-bottom: 20px;
        }
        .InstructorPayoutsListPayout {
          padding: 10px 0px;
          border-bottom: 1px solid $offWhiteColor2;
          display: flex;
          flex-flow: row wrap;
          justify-content: flex-start;
          align-items: center;

          .InstructorPayoutsListPayoutDate {
            margin: 10px 16px 10px 0px;
            @include fontSizeNormal();
            color: $grayColor1;
          }
          .InstructorPayoutsListPayoutAmount {
            margin: 10px 16px 10px 0px;
            @include fontSizeMedium();
            font-weight: bold;
            color: $darkColor;
            min-width: 80px;
          }
          .InstructorPayoutsListPayoutStatus {
            @include fontSizeNormal();
            color: $themeColor1;
            padding: 5px;
            width: 100px;
            background: lighten($themeColor1, 30%);
            border-radius: 8px;
            margin: 10px 16px 10px 0px;
          }
          .InstructorPayoutsListPayoutStatusSuccess {
            color: $themeColor2;
            background: lighten($themeColor2, 30%);
          }
          .InstructorPayoutsListPayoutStatusFailed {
            color: $themeColor3;
            background: lighten($themeColor3, 45%);
          }
          .InstructorPayoutsListPayoutMethod {
            margin: 10px 16px 10px 0px;
            @include fontSizeNormal();
            color: $grayColor0;
          }
          .InstructorPayoutsListPayoutMethodData {
            margin: 10px 16px 10px 0px;
            @include fontSizeNormal();
            color: $grayColor1;
          }
          .InstructorPayoutsListPayoutMessage {
            margin: 10px 0px 10px 0px;
            @include fontSizeNormal();
            color: $darkColor;
            text-align: left;
            width: 300px;
          }
        }
      }
    }
  }

  .InstructorConnectVenmo {
    text-align: center;
    padding: 10px $mainPaddingSize;

    .InstructorConnectVenmoTitle {
      @include fontSizeTitle();
      color: $darkColor;
      padding-top: 40px;
      padding-bottom: 10px;
    }
    .InstructorConnectVenmoDescription {
      @include fontSizeNormal();
      color: $darkColor;
      padding-bottom: 20px;
    }
  }

  .InstructorAdmin {
    text-align: center;
    padding: 10px $mainPaddingSize;

    .InstructorAdminContent {
      .InstructorAdminContentTitle {
        @include fontSizeTitle();
        color: $darkColor;
        padding-top: 40px;
        padding-bottom: 10px;
      }
      .InstructorAdminContentDescription {
        @include fontSizeNormal();
        color: $darkColor;
        padding-bottom: 20px;
      }

      .InstructorAdminList {
      }
      .InstructorAdminListRow {
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-start;
        align-items: center;
        padding: 10px 0px;
        border-top: 1px solid $offWhiteColor2;

        .InstructorAdminListRowPhoto {
          width: 100px;
          height: 100px;
          border-radius: 50px;
          overflow: hidden;
          margin: 10px 20px 10px 0px;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .InstructorAdminListRowInfo {
          display: flex;
          flex-flow: column nowrap;
          justify-content: center;
          align-items: flex-start;
          width: 300px;
          margin: 10px 20px 10px 0px;

          .InstructorAdminListRowName {
            @include fontSizeMedium();
            color: $darkColor;
          }
          .InstructorAdminListRowEmail {
            @include fontSizeNormal();
            color: $grayColor1;
          }
        }

        .InstructorAdminListRowStatus {
          @include fontSizeNormal();
          color: $themeColor3;
          padding: 5px;
          width: 100px;
          background: lighten($themeColor3, 30%);
          border-radius: 8px;
          margin-right: 20px;
        }
        .InstructorAdminListRowStatusNew {
          color: $grayColor0;
          background: $offWhiteColor;
        }
        .InstructorAdminListRowStatusWaiting {
          color: $themeColor2;
          background: lighten($themeColor2, 30%);
        }
        .InstructorAdminListRowStatusApproved {
          color: $themeColor1;
          background: lighten($themeColor1, 30%);
        }
      }
    }
  }

  @media (max-width: $breakSmall) {
    min-height: calc(100% - 48px);
    .ClassPurchases {
      padding: 10px $breakPaddingSize;
    }
    .InstructorPayouts {
      padding: 10px $breakPaddingSize;
    }
    .InstructorAdmin {
      padding: 10px $breakPaddingSize;
    }
    .InstructorConnectVenmo {
      padding: 10px $breakPaddingSize;
    }
    .ProfileTab {
      padding: 10px $breakPaddingSize;
    }
    .ProfileEditBar {
      padding: 0px 0px 0px $breakPaddingSize;
    }
    .ProfileForm {
      padding: 50px $breakPaddingSize;
    }
    .ClassList {
      padding: 50px $breakPaddingSize;

      .ClassListClass {
        .ClassListClassVideo {
          flex-basis: $breakSmall / 2;
        }
        .ClassListClassContent {
          flex-basis: $breakSmall / 2;
          padding: 0px 0px 25px 0px;
        }
      }
    }
    .InstructorProfile {
      .InstructorProfileTop {
        .InstructorProfileContent {
          left: $breakPaddingSize;
        }
      }
      .InstructorProfileAbout {
        .InstructorProfileAboutLeft {
          flex-basis: 100%;
          padding-left: $breakPaddingSize;
        }
        .InstructorProfileAboutRight {
          flex-basis: 100%;
        }
      }
    }
    .InstructorProfileClasses {
      .InstructorProfileClass {
        .InstructorProfileClassLeft {
          flex-basis: 100%;
        }
        .InstructorProfileClassRight {
          flex-basis: 100%;
        }
      }
    }
  }
}

.VideoPlayerDivPreview {
  width: 100%;
  height: 100%;

  .VideoPlayerDivPreviewImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.VideoPlayerDiv {
  height: 450px;
  max-width: 100%;
  //background: $darkColor;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  //border-radius: 20px;
  overflow: hidden;

  .VideoPlayerDivContainer {
    //background: $darkColor;
    width: 100% !important;
    height: 100%;

    iframe {
      width: 100% !important;
      height: 100% !important;
    }
  }
  .VideoPlayerDivError {
    background: $darkColor;
    color: $whiteColor;
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;

    .VideoPlayerDivErrorTitle {
      @include fontSizeTitle();
      padding-bottom: 12px;
    }
    .VideoPlayerDivErrorDescription {
      @include fontSizeNormal();
    }
  }
}
.VideoPlayerDivLoading {
  .VideoPlayerDivContainer {
    background: $darkColor;
  }
}

@media (max-width: 1440px) {
  .VideoPlayerDiv {
    height: 400px;
  }
}
@media (max-width: 1200px) {
  .VideoPlayerDiv {
    height: 350px;
  }
}
@media (max-width: 1024px) {
  .VideoPlayerDiv {
    height: 300px;
  }
}
@media (max-width: $breakSmall) {
  .VideoPlayerDiv {
    height: 450px;
  }
}
@media (max-width: 700px) {
  .VideoPlayerDiv {
    height: 400px;
  }
}
@media (max-width: 600px) {
  .VideoPlayerDiv {
    height: 350px;
  }
}
@media (max-width: 500px) {
  .VideoPlayerDiv {
    height: 300px;
  }
}
@media (max-width: 414px) {
  .VideoPlayerDiv {
    height: 250px;
  }
}
@media (max-width: 320px) {
  .VideoPlayerDiv {
    height: 180px;
  }
}
