@import "../DarwinReactLibrary/main";
@import "./partials/_fonts";

//Floww Colors.
$whiteColor: #FFFFFF;
$offWhiteColor: #FAFAFA;

//$grayColor1: #C0C1C4;
//$grayColor2: #96979C;
//$grayColor3: #6F7177;
$grayColor0: #B8B8B8;
$grayColor1: #868686;
$grayColor2: #404040;
$grayColor3: #a6a6a6;
$grayColor4: #333333;
$grayColor5: #4d4d4d;

$darkColor: #000000;
$darkColor2: #131313;

//$redColor: #C03221;
//$blueColor: #1D4D87;
//$zochaGreen: #1E874B;

$redColor: #FF2929;
$blueColor: #2997FF;
$yellowColor: #E7E521;
$greenColor: #95D9C3;
$facebookColor: #227AEE;

$themeColor1: #FFCD00;
$themeColor2: #461D7C;
$themeColor3: #E00C2E;

$actionColor: $themeColor1;

//Dimensions
$contentMinHeight: 530px;
$breakSmall: 800px; //size at which the sidebar shows and hides on the screen.
$maxWidth: 1280px;
$mainPaddingSize: 80px;
$mainPadding: 0px $mainPaddingSize;
$breakPaddingSize: 12px;
$breakPadding: 0px $breakPaddingSize;

//UNUSED
$zochaGreen: #1E874B;
$offWhiteColor2: #F2F2F3;
$lightGrayColor1: #C0C0C0;
$lightGrayColor2: #AAAAAA;

$payPalColor: #0070BA;
$venmoColor: #3894D0;

//Common Mixins
@mixin nunitoFont() {
  font-family: Nunito, Helvetica, Arial, sans-serif;
}
@mixin gangsterFont() {
  font-family: gangstergrotesk, Helvetica, Arial, sans-serif;
}

@mixin fontSizeHuge() {
  font-size: 68px;
  font-weight: 600;
}
@mixin fontSizeTitle() {
  font-size: 36px;
  font-weight: 600;
}
@mixin fontSizeStat() {
  font-size: 24px;
  font-weight: 300;
}
@mixin fontSizeMedium() {
  font-size: 18px;
  font-weight: 300;
}
@mixin fontSizeMid() {
  font-size: 16px;
  font-weight: 300;
}
@mixin fontSizeNormal() {
  font-size: 15px;
  font-weight: 300;
}
@mixin fontSizeSmall() {
  font-size: 12px;
  font-weight: 300;
}
@mixin fontSizeTiny() {
  font-size: 10px;
  font-weight: 300;
}

@mixin darkForm() {
  a {
    color: $offWhiteColor;
    text-decoration: underline;
  }

  .dualImageSingle {
      margin-top: 50px;
  }

  .Title {
    color: $offWhiteColor;
    @include fontSizeTitle();
    padding: 20px 0px 10px 0px;
  }

  .Description {
    padding: 20px 0px;
    @include fontSizeNormal();
  }

  .Description2 {
    padding-top: 0px;
    @include fontSizeNormal();
  }
  .Description2Light {
    @include fontSizeNormal();
    color: $whiteColor;
    font-weight: bold;
    padding: 8px 0px;
  }
  .Description3 {
    padding-top: 5px;
    @include fontSizeNormal();
    font-weight: 400;
  }

  .ErrorDiv {
    @include fontSizeSmall();
    color: $redColor;
    text-align: center;
    padding: 12px 12px 0px 12px;
  }

  .inputBottomLineLightContainer {
    .inputBottomLineLight {
      background: $darkColor2;
      color: $offWhiteColor;
    }
    .inputBottomLineTitle {
      color: $offWhiteColor;
    }
    .inputBottomLineDescription {
      color: $lightGrayColor1;
    }
    .inputBottomLineLightDisplay {
      background: $darkColor;
      color: $offWhiteColor;
      text-align: center;
    }
    .inputBottomLineLightFlexBox {
      background-color: $darkColor2;
      color: $offWhiteColor;

      .dualImageSingle {
        margin: 0px;
      }
    }
  }

  // React Select Box
  .react-select-container {
    margin: 2px 0px 0px 0px;

    .react-select__control {
      box-shadow: none;
      background: $darkColor2;

      .react-select__value-container {

        .react-select__single-value {
          color: $offWhiteColor;
        }
        .react-select__input {
          color: $offWhiteColor;
        }
      }
      .react-select__indicators {

        .react-select__indicator-separator {
          background-color: $grayColor3;
        }
        .react-select__dropdown-indicator {
          color: $grayColor1;
        }
      }
    }
    .react-select__menu {
      box-shadow: none;
      background: $offWhiteColor;

      .react-select__menu-list {
        background: $offWhiteColor;

        .react-select__option {
          color: $darkColor;
          background: $offWhiteColor;
        }
        .react-select__option--is-focused {
          background-color: $grayColor2;
          color: $offWhiteColor;
        }
        .react-select__option--is-selected {
          background-color: $darkColor2;
          color: $offWhiteColor;
        }
      }
    }
  }

  // Input placeholder text
  ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: $grayColor0;
  }
  ::-moz-placeholder { /* Firefox 19+ */
    color: $grayColor0;
  }
  :-ms-input-placeholder { /* IE 10+ */
    color: $grayColor0;
  }
  :-moz-placeholder { /* Firefox 18- */
    color: $grayColor0;
  }
}

@mixin ellipsis() {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

@mixin boxShadow() {
  box-shadow: 0px 2px 4px 0px transparentize($darkColor, 0.80);
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);

  &:hover {
    box-shadow: 0px 2px 6px 0px transparentize($darkColor, 0.80);
  }
}
@mixin boxShadowLight() {
  box-shadow: 0px 1px 2px 0px transparentize($darkColor, 0.88);
}

@mixin greenGradient() {
  background: $zochaGreen;
  background: -moz-linear-gradient(225deg, $zochaGreen 0%, lighten($zochaGreen, 0.1) 100%);
  background: -webkit-linear-gradient(225deg, $zochaGreen 0%, lighten($zochaGreen, 100%) 100%);
  background: linear-gradient(225deg, lighten($zochaGreen, 20%) 0%, $zochaGreen 100%);
}

//Base Properties
html {
  background: $whiteColor;
}
body {
  background: $whiteColor;
  @include gangsterFont();
  font-weight: 300;
  font-size: 14px;
  color: $darkColor;
}

// App specific Elements - Containers
.App {
  position: relative;
  height: 100%;
  width: 100%;
}
.AppContainer {
  height: 100%;
  width: 100%;
}
.MainBody {
  height: 100%;
  width: 100%;
}

//Properties for the Portal
.Portal {
  display: block;
  min-height: 100%;
  height: 100%;
  //min-height: $contentMinHeight;
  background: $whiteColor;

  @media (max-width: $breakSmall) {
    //display: block;
  }
  @media (max-height: $contentMinHeight) {
    min-height: $contentMinHeight;
  }
}


.TODOTitle {
  @include fontSizeTitle();
  text-align: center;
  padding: 60px 0px 0px 0px;
}


///////////////////////////

///////////////////////////

// ZOCHA COPY AFTER THIS //

///////////////////////////

///////////////////////////



.PortalCenterCenter {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  min-height: 100%;

  .PortalCenterCenterContent {
    text-align: center;
  }
}
.SContainer {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  height: 100%;
  text-align: center;

  .SContainerTitle {
    @include fontSizeTitle();
    color: $grayColor2;
    margin: 15px auto 30px auto;
  }
  .SContainerTitle2 {
    @include fontSizeTitle();
    color: $grayColor2;
    margin: 20px auto 10px auto;
  }
  .SContainerDescription {
    @include fontSizeNormal();
    color: $grayColor2;
    margin: 0px auto 30px auto;
    padding: 0px 15px;
    max-width: 500px;
  }
  .SContainerDescription2 {
    @include fontSizeNormal();
    color: $grayColor2;
    margin: 0px auto 30px auto;
    padding: 30px 15px 0px 15px;
    max-width: 500px;
  }
  .MaterialButton {

  }
}

.FullScreenLoading {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}

.PageTitle {
  @include fontSizeTitle();
  color: $darkColor;
  display: inline-block;
  margin-right: 85px;
}
.PageHeader {
  @include fontSizeTitle();
  color: $darkColor;
  margin: 0px 0px 5px 0px;
}
.PageHeader2 {
  @include fontSizeTitle();
  color: $grayColor2;
  margin: 0px 0px 5px 0px;
}
.PageHeader3 {
  @include fontSizeTitle();
  color: $darkColor;
  margin: 0px 0px 40px 0px;
}
.PageDescription {
  max-width: 500px;
  margin: 0px auto;
  @include fontSizeNormal();
  color: $grayColor2;
}
.PageDescription2 {
  margin: 0px auto;
  @include fontSizeNormal();
  color: $grayColor2;
}
.PageDescription3 {
  max-width: 500px;
  margin: 0px auto 40px auto;
  @include fontSizeNormal();
  color: $grayColor2;
}
.PageDescriptionRed {
  max-width: 500px;
  margin: 10px auto;
  @include fontSizeNormal();
  color: $redColor;
}

.PageLink {
  @include fontSizeNormal();
  color: $darkColor;
  font-weight: 400;
  padding: 4px 20px 4px 0px;

  &:hover {
    color: $darkColor;
  }
}

.Filters {
  display: inline-block;
  @include fontSizeNormal();
  font-weight: 500;

  .Filter {
    @include pushable();
    display: inline-block;
    color: $grayColor2;
    margin-right: 30px;
    vertical-align: baseline;

    span {
      margin-right: 4px;
    }
  }
  .FilterActive {
    color: $darkColor;
  }
}

.PageIndicator {
  display: flex;
  flex-flow: row wrap;

  .PageIndicatorButton {
    @include fontSizeNormal();
    min-width: 20px;
    text-align: center;
    background: $offWhiteColor2;
    color: $grayColor2;
    border-radius: 4px;
    margin: 0px 4px 0px 0px;
    @include pushable();
  }
  .PageIndicatorButtonSelected {
    background: $grayColor2;
    color: $whiteColor;
  }
  .PageIndicatorDots {
    @include fontSizeNormal();
    min-width: 20px;
    text-align: center;
    color: $grayColor2;
    border-radius: 4px;
    margin: 0px 4px 0px 0px;
  }
  .PageIndicatorCount {
    @include fontSizeNormal();
    text-align: center;
    color: $grayColor2;
    padding-right: 8px;
  }
}

//Table
.TableContainer {
  margin-top: 15px;
  background: $whiteColor;
  width: 100%;
  border-radius: 9px;
  box-shadow: 0 4px 8px 0px rgba(0, 0, 0, 0.15);
  overflow: hidden;

  .TableTop {
    padding: 13px 20px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;

    img {
      margin-right: 5px;
    }

    .TableTopDate {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
    }
    .TableTopDownload {
      @include pushable();
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
    }

    .TableTopDate1 {
      color: $grayColor2;
      padding-left: 4px;
    }
    .TableTopDate2 {
      @include pushable();
      text-transform: uppercase;
      margin-left: 20px;
      color: $darkColor;

      .TableTopDate2Text {
        margin-right: 5px;
      }
    }
  }

  table {
    width: 100%;
    user-select: none;

    .headerRow {
      background: $offWhiteColor2;

      &:hover {
        background: $offWhiteColor2;
      }
    }
    tr {
      height: 46px;
      border-bottom: 1px solid $offWhiteColor;

      transition: all 0.3s;
    	cursor: pointer;

      &:hover {
        background: $offWhiteColor2;
      }

    	&:active {
    		transform: translateY(2px);
    	}

      td:nth-child(1), th:nth-child(1) {
        padding-left: 12px;
      }
      td:nth-last-child(1), th:nth-last-child(1) {
        padding-right: 12px;
      }
    }
    th {
      @include fontSizeNormal();
      color: $grayColor2;
    }
    td {
      @include fontSizeNormal();
      color: $darkColor;
    }
    .tdMoney {
      font-weight: 600;
    }
    .tdNote {
      width: 30%;
    }
    .statusBull {
      margin-right: 5px;
    }
    .tdStatus {
      font-weight: 500;
    }
  }

  .emptyTable {
    @include fontSizeNormal();
    padding: 15px;
    text-align: center;
    color: $grayColor2;
  }
}

//Request Table
.tdStatusUnpaid {
  color: $darkColor;
}
.tdStatusCompleted {
  color: $zochaGreen;
}
.tdStatusRequested {
  color: $darkColor;
}
.tdStatusPending {
  color: $blueColor;
}
.tdStatusCancelled {
  color: $grayColor1;
}
.tdStatusFailed {
  color: $redColor;
}
//User Table
.tdStatusUnverified {
  color: $grayColor1;
}
.tdStatusBeneficial {
  color: $grayColor3;
}
.tdStatusVerified {
  color: $blueColor;
}
.tdStatusSuspended {
  color: $redColor;
}

@media (max-width: 1000px) {
  .tdHideable {
    display: none;
  }
}
@media (max-width: 400px) {
  .tdHideable2 {
    display: none;
  }
}
@media (max-width: 1100px) {
  .tuHideable {
    display: none;
  }
}
@media (max-width: 500px) {
  .tuHideable2 {
    display: none;
  }
}
@media (max-width: 1000px) {
  .tsHideable {
    display: none;
  }
}
@media (max-width: 400px) {
  .tsHideable2 {
    display: none;
  }
}

//Inputs
.ButtonDiv {
  margin: 10px auto 0px auto;
}
.ForgotPasswordDiv {
  margin: 10px auto 0px auto;
}
.InputDiv {
  padding: 10px 0px 0px 0px;
}
.InputDivHideInvalid {
  .inputBottomLineLightContainer {
    .inputBottomLineInvalid {
      min-height: 0px;
    }
  }
}
.InputExpander {
  text-align: left;
  max-width: 420px;
  margin: 0px auto 20px auto;
  cursor: pointer;
  @include fontSizeNormal();
  font-weight: 400;
}
.InputBreakDiv {
  padding: 0px 0px 0px 0px;
}
.InputSection {
  margin: 0px auto;
  padding: 20px 0px 0px;
  @include fontSizeStat();
  color: $darkColor;
  text-align: left;
  width: 480px;

  @media (max-width: 480px) {
    width: 280px;
  }
}
.SettingsToggle {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  margin: 10px 0px;

  .SettingsToggleName {
    padding-left: 15px;
    @include fontSizeNormal();
    color: $grayColor0;
  }
}
.inputBottomLineLightContainer {
  width: 350px;
  text-align: center;
  display: inline-block;
  position: relative;

  @media (max-width: 460px) {
    width: 260px;
  }

  .inputBottomLineTitle {
    @include fontSizeMedium();
    color: $grayColor0;
    text-transform: none;
    text-align: left;
    padding: 0px 12px 0px 2px;
    transition: all 0.5s;
    position: relative;
    line-height: 25px;
    top: 29px;
    z-index: 100;
    pointer-events: none;
    filter:progid:DXImageTransform.Microsoft.AlphaImageLoader(src='your_transparent.png', sizingMethod='scale');
    background: none !important;
  }
  .inputBottomLineTitleActive {
    top: 0px;
    @include fontSizeNormal();
  }
  .inputBottomLineDescription {
    @include fontSizeNormal();
    text-align: left;
    padding: 0px 12px 4px 12px;
  }
  .inputBottomLineNote {
    @include fontSizeTiny();
    font-weight: 300;
    color: $grayColor2;
    text-align: left;
    padding: 10px 12px 0px 0px;
  }
  .inputBottomLineFee {
    @include fontSizeTiny();
    font-weight: 400;
    color: $grayColor2;
    text-align: left;
    padding: 10px 12px 0px 0px;
  }

  .inputBottomLineInvalid {
    @include fontSizeSmall();
    text-transform: none;
    min-height: 17px;
    visibility: hidden;
    color: $redColor;
    text-align: right;
    padding: 0px 12px 0px 12px;
    margin-top: 5px;
  }

  .inputBottomLineLightFlexBox {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 40px;
    padding: 0px;
    margin: 0px;
    @include fontSizeMedium();
    background-color: transparent;
    background-image: none;
    text-align: left;
    border-radius: 0px;
    box-shadow: none;
    border: 0px;
    color: $darkColor;

    .inputBottomLineIcon {
      height: 20px;
      padding: 0px 12.5px 0px 0px;
      transition: all 500ms ease-in-out;
    }
    .inputBottomLineIconHidden {
      opacity: 0.0;
      width: 0px;
      padding: 0px;
    }

    .manualSelect {
      width: 100%;
      height: 45px;
      -webkit-appearance: none;
      outline: none;
      box-shadow: none;
      border: 0px;
      background-color: $offWhiteColor2;
      background-image: none;
      margin: 0px;
      padding: 0px 12.5px;
      color: $darkColor;
    }
  }
  .inputBottomLineLightFlexBoxClear {
    background: transparent;

    .inputBottomLineIcon {
      padding-left: 10px;
    }
    .inputBottomLineIconHidden {
      padding: 0px;
    }
  }
  .inputBottomLineMobileDate {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 45px;
    padding: 0px;
    margin: 0px;
    @include fontSizeMedium();
    background-color: transparent;
    background-image: none;
    text-align: left;
    border-radius: 0px;
    box-shadow: none;
    border: 0px;
    color: $darkColor;

    input {
      vertical-align: middle;
      -webkit-appearance: none;
      -moz-appearance: textfield;
    }

    .inputBottomLineIcon {
      height: 20px;
      padding: 0px 12.5px 0px 0px;
      transition: all 500ms ease-in-out;
    }
    .inputBottomLineIconHidden {
      opacity: 0.0;
      width: 0px;
      padding: 0px;
    }
  }
  .GoogleMapsDropdownContainer {
    margin: 5px;
    @include fontSizeNormal();
    color: $grayColor2;

    .GoogleMapsDropdownContainerLoading {
      display: none;
    }
    .GoogleMapsItem, .GoogleMapsItemActive {
      @include pushable();
      padding: 5px;
      border-radius: 8px;
    }
    .GoogleMapsItemActive {
      background: $offWhiteColor2;
    }
  }

  .inputBottomLineLight {
    display: inline-block;
    width: 100%;
    height: 40px;
    padding: 4px 12.5px 9px 2px;
    margin: 0px;
    @include fontSizeMedium();
    background-color: transparent;
    background-image: none;
    text-align: left;
    border-radius: 0px;
    box-shadow: none;
    border: 0px;
    color: $darkColor;
    border-bottom: 2px solid $grayColor0;
    transition: all 0.5s;

    &:focus {
      outline: 0px !important;
      border-bottom: 2px solid black;
    }
  }
  .inputBottomLineBlockLight {
  	height: 1px;
  	width:calc(100% - 24px);
  	margin:0px 0px 0px 12px;
  	background-color: $darkColor;
  	transition: background-color 0.5s ease, width 0.5s ease;
  }
  .inputBottomLineLight:focus ~ .inputBottomLineBlockLight {
  	background-color: $grayColor2;
  	width:calc(100% - 24px);
  }

  .inputBottomLineCheckbox {
    display: flex;
    flex-flow: row nowrap;
    padding: 4px 12px 0px 12px;
    justify-content: flex-start;
    align-items: stretch;

    //this is the thing we are clicking
    label {
      width: 22px;
      height: 22px;
      border-radius: 4px;
      @include pushable();
      transition: all 300ms;

      .dualImageSingle {
        margin: 0px;
        vertical-align: middle;
        width: 14px;
      }
    }
    .inputBottomLineCheckboxTrue {
      background: $darkColor2;
    }
    .inputBottomLineCheckboxFalse {
      background: $offWhiteColor;
    }
    span {
      @include fontSizeMid();
      color: $offWhiteColor;
      padding: 0px 0px 0px 16px;
    }
    //hide the input
    input {
      border: 0;
      clip: rect(0 0 0 0);
      clippath: inset(50%);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      white-space: nowrap;
      width: 1px;
    }
  }
  .inputBottomLineCheckboxLight {
    .inputBottomLineCheckboxTrue {
      background: $darkColor2;
    }
    .inputBottomLineCheckboxFalse {
      background: $whiteColor;
      border: 1px solid $grayColor2;
    }
    span {
      color: $grayColor2;
    }
  }
}


.inputBottomLineLightContainerInvalid {

  .inputBottomLineInvalid {
    visibility: visible;
  }

  .inputBottomLineLight {
    color: $redColor;
  }
  .inputBottomLineBlockLight {
    //background-color: $redColor;
  }
  .inputBottomLineLight:focus ~ .inputBottomLineBlockLight {
  	background-color: $redColor;
  }
}
.inputBottomLineLightContainerValid {

  .inputBottomLineLight {
    color: $darkColor;
  }
  .inputBottomLineBlockLight {
    //background-color: $redColor;
  }
  .inputBottomLineLight:focus ~ .inputBottomLineBlockLight {
  	background-color: $darkColor;
  }
}

//Box Style of input for WYSIWYG forms.
.BoxedInput {
  border: 1px dashed $themeColor2;
  margin: 5px;

  .inputBottomLineInvalid {
    display: none;
  }
  .inputBottomLineLightFlexBox {
    height: auto;
  }
  .inputBottomLineLight {
    color: $whiteColor;
    height: auto;
    padding: 2px 6px;
    border-bottom: 0px solid $grayColor0;

    &:focus {
      border-bottom: 0px solid black;
    }
  }
  .inputBottomAreaLight {
    transition: all 0.5s;
    color: $whiteColor;
    height: auto;
    min-height: 79px;
    width: 100%;
    background: transparent;
    padding: 2px 6px;
    border: 0px;

    &:focus {
      outline: 0px !important;
    }
  }
}
.BoxedInputLarge {
  .inputBottomLineLight {
    @include fontSizeTitle();
  }
}
.BoxedInputLargeNormal {
  .inputBottomLineLight {
    @include fontSizeTitle();
    font-weight: normal;
  }
}

// Button Styles
.MaterialButton {
  color: $whiteColor;
  @include fontSizeMedium();
  padding: 7px 20px;
  border-radius: 9px;
  min-width: 260px;
  background-color: $whiteColor;
  @include pushable();
  transition: all 0.5s;
  //transition: background-color 0.5s, box-shadow 0.5s, color 0.5s, transform 0.5s;
  vertical-align: top;
  text-align: center;

  &:hover, &:active, &:focus {
    color: $whiteColor;
    background-color: lighten($darkColor, 10%);
  }

  img {
    height: 18px;
    vertical-align: middle;
    margin-right: 18px;
  }
  span {
    vertical-align: middle;
  }
}
.MaterialButtonFull {
  width: 100%;
}
.MaterialButtonLarge {
  min-width: 220px;
}

.MaterialButtonLarge {
  width: 420px;

  @media (max-width: 460px) {
    width: 280px;
  }
}
.MaterialButtonSmall {
  min-width: 275px;
  margin: 4px;
}
.MaterialButtonContent {
  min-width: 50px;
  margin: 4px;
}
.MaterialButtonTiny {
  min-width: 50px;
  margin: 4px;
  @include fontSizeTiny();
}


.react-datepicker-wrapper {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 45px;
  padding: 0px;
  margin: 0px;
  @include fontSizeMedium();
  background-color: transparent;
  background-image: none;
  text-align: left;
  border-radius: 8px;
  box-shadow: none;
  border: 0px;
  color: $darkColor;

  .react-datepicker__input-container {

    input {
      display: inline-block;
      width: 100%;
      height: 45px;
      padding: 9px 12.5px 9px 2px;
      margin: 0px;
      @include fontSizeMedium();
      background-color: transparent;
      background-image: none;
      text-align: left;
      border-radius: 0px;
      box-shadow: none;
      border: 0px;
      color: $darkColor;
      border-bottom: 2px solid $grayColor0;
      transition: all 0.5s;

      &:focus {
        outline: 0px !important;
        border-bottom: 2px solid black;
      }
    }
  }
}
.react-datepicker-popper {
  z-index: 200;

  select {
    -webkit-appearance: none;
    outline: none;
    box-shadow: none;
    border: 0px;
    background-color: $offWhiteColor2;
    background-image: none;
    //margin: 0px;
    padding: 0px 12.5px;
    color: $darkColor;
  }

  .react-datepicker {
    @include fontSizeNormal();
    border-radius: 8px;
    overflow: hidden;
    border-color: $grayColor1;

    .react-datepicker__navigation--previous {
      border-right-color: $grayColor1;
    }
    .react-datepicker__navigation--next {
      border-left-color: $grayColor1;
    }
    .react-datepicker__month-container {
      .react-datepicker__header {
        background-color: $offWhiteColor;
        padding: 8px 8px 0px 8px;
        border-bottom: 1px solid $offWhiteColor2;

        .react-datepicker__current-month--hasMonthDropdown {
          @include fontSizeTiny();
          margin-bottom: 8px;
          color: $darkColor;
        }
        .react-datepicker__day-names {
          margin: 8px 0px 0px 0px;
          .react-datepicker__day-name {
            @include fontSizeTiny();
            color: $darkColor;
            width: 22px;
          }
        }
        .react-datepicker__month-dropdown-container {
          margin-right: 8px;
        }
        .react-datepicker__month-dropdown-container, .react-datepicker__year-dropdown-container {
          .react-datepicker__month-select, .react-datepicker__year-select {
            border: 1px solid $grayColor1;
            background: $whiteColor;
            @include fontSizeNormal();
            color: $darkColor;
          }
        }
      }
      .react-datepicker__month {
        background: $whiteColor;
        margin: 8px;

        .react-datepicker__day {
          width: 22px;
          color: $darkColor;
          @include fontSizeNormal();
          margin: 2px;

          &:hover {
            background-color: $offWhiteColor2;
          }
        }
        .react-datepicker__day--selected {
          background: $grayColor1;
        }
        .react-datepicker__day--disabled {
          color: $grayColor1;
        }
      }
    }
  }
}
.MaterialButtonDate {
  background-color: $offWhiteColor2;
  color: $darkColor;
  border: 2px solid $offWhiteColor;
  width: 100%;
  height: 44px;
  text-align: left;

  &:hover, &:active, &:focus {
    background-color: $offWhiteColor2;
    color: $darkColor;
  }

  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;

  .ButtonDivContent {
    padding: 0px 20px 0px 0px;
  }

  .inputBottomLineIcon {
    height: 20px;
    padding: 0px;
    margin: 0px;
    transition: all 500ms ease-in-out;
  }
  .inputBottomLineIconHidden {
    opacity: 0.0;
    width: 0px;
    padding: 0px;
  }
}
.MaterialButtonDateUnset {
  color: $grayColor2;

  &:hover {
    color: $grayColor2;
  }
}
.MaterialButtonDark {
  //background-color: $zochaGreen;
  color: $offWhiteColor;
  @include boxShadow();
  border: 0px solid $zochaGreen;
  background-color: $zochaGreen;
  //@include greenGradient();
  padding: 9px 20px;

  &:hover, &:active, &:focus {
    background-color: $zochaGreen;
    color: $offWhiteColor;
    border: 0px solid $whiteColor;
  }
}
.MaterialButtonDarkText {
  background-color: $darkColor;
  color: $offWhiteColor2;

  &:hover, &:active, &:focus {
    background-color: $darkColor;
    color: $lightGrayColor1;
  }
}
.MaterialButtonDarkActive {
  background-color: $offWhiteColor;
  color: $darkColor;
  border: 0px solid $offWhiteColor;
  padding: 9px 20px;

  &:hover, &:active, &:focus {
    background-color: $offWhiteColor;
    color: $darkColor;
  }
}
.MaterialButtonLight {
  background-color: $whiteColor;
  color: $grayColor2;
  @include boxShadow();
  //border: 2px solid $grayColor2;

  &:hover, &:active, &:focus {
    background-color: $whiteColor;
    color: $grayColor2;
  }
}
.MaterialButtonLightText {
  background-color: $offWhiteColor;
  color: $grayColor2;

  &:hover, &:active, &:focus {
    background-color: $offWhiteColor;
    color: $grayColor2;
  }
}
.MaterialButtonSidebar {
  background-color: $darkColor;
  color: $offWhiteColor;
  border-radius: 0px 9px 9px 0px;
  min-width: 235px;
  max-width: 255px;

  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;

  margin: 0px 0px 12px 0px;

  &:hover, &:active, &:focus {
    background-color: $darkColor;
    color: $offWhiteColor;
  }
}
.MaterialButtonSidebarActive {
  background-color: $offWhiteColor;
  color: $darkColor;
  border-radius: 0px 9px 9px 0px;
  min-width: 235px;

  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;

  margin: 0px 0px 12px 0px;

  &:hover, &:active, &:focus {
    background-color: $offWhiteColor;
    color: $darkColor;
  }
}

hr {
  border-top: 1px solid $offWhiteColor2;
}

// Input placeholder text
::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: $grayColor0;
}
::-moz-placeholder { /* Firefox 19+ */
  color: $grayColor0;
}
:-ms-input-placeholder { /* IE 10+ */
  color: $grayColor0;
}
:-moz-placeholder { /* Firefox 18- */
  color: $grayColor0;
}

//Now import the partials, must be after the variables and mixins so they can be used within the partials.
@import "./partials/_createAccount.scss";
@import "./partials/_footer.scss";
@import "./partials/_home.scss";
@import "./partials/_instructors.scss";
@import "./partials/_instructor.scss";
@import "./partials/_join.scss";
@import "./partials/_legal.scss";
@import "./partials/_login.scss";
@import "./partials/_profile.scss";
@import "./partials/_topbar.scss";
@import "./partials/_404.scss";
@import "./partials/_zoomAuth.scss";

//////////////////////////////

//////////////////////////////

// ANCIENT STUFF AFTER THIS //

//////////////////////////////

//////////////////////////////

//Old Colors.
$grayColor: #B3B1B5;
$purpleColor: #8B4CFF;
$purpleLightColor: #D7C2FF;
$redLightColor: #FFC2D0;
$greenColor: #3EC43F;
$greenLightColor: #B5F6B0;
$payPalColor: #0070BA;

@mixin textShadow() {
  text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.5);
}

//Old Font Sizes
@mixin fontSizeMassive() {
  font-size: 50px;
  letter-spacing: 1.6px;
  font-weight: 600;
}
@mixin fontSizeHuge() {
  font-size: 44px;
  letter-spacing: 0.66px;
  font-weight: 500;
}
@mixin fontSizeLarge() {
  font-size: 32px;
  letter-spacing: 0.48px;
  font-weight: 300;
}
@mixin fontSizeMidPlus() {
  font-size: 28px;
  letter-spacing: 1.4px;
  font-weight: 400;
}
@mixin fontSizeMid() {
  font-size: 24px;
  letter-spacing: 0.36px;
  font-weight: 400;
}
@mixin fontSizeSpaced() {
  font-size: 16px;
  letter-spacing: 0.8px;
  font-weight: 400;
}
@mixin fontSizeMicro() {
  font-size: 10px;
  letter-spacing: 0px;
  font-weight: 300;
}

//Now import the partials, must be after the variables and mixins so they can be used within the partials.
@import "./partials/_components.scss";

// A full screen popup.
.Popup {
  width: 100%;
  height: 100%;
  background-color: $whiteColor;
  position: fixed;
  z-index: 100000;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  @include fadeIn();
  text-align: center;
  overflow: scroll;

  .PopupContainer {
    margin: auto;

    .PopupMain {
      @include fontSizeTitle();
      color: $redColor;
      font-weight: 600;
    }
    .PopupTitle {
      @include fontSizeTitle();
      color: $darkColor;
      font-weight: 300;
      padding-top: 10px;
    }
    .PopupDescription {
      @include fontSizeNormal();
      color: $grayColor1;
      font-weight: 300;
      padding: 20px 0px;
    }
    .PopupPasswordError {
      color: $redColor;
      font-weight: 300;
      padding-top: 30px;
    }
  }
}
.PopupSuccess {

  .PopupContainer {

    .PopupMain {
      color: $darkColor;
    }
    .PopupTitle {
    }
    .PopupDescription {
    }
  }
}

// Tool Tips
.__react_component_tooltip {
  border-radius: 17px;
  padding: 4px 15px;
  font-size: 14px;
  font-weight: 300;
}
.__react_component_tooltip.show {
  opacity: 1.0;
}
.__react_component_tooltip.type-dark {
  background-color: $grayColor2;
}
.__react_component_tooltip.type-dark.place-top:after {
  border-top-color: $grayColor2;
}
.__react_component_tooltip.type-light {
  background-color: $grayColor3;
  color: $grayColor2;
}
.__react_component_tooltip.type-light.place-top:after {
  border-top-color: $grayColor3;
}
.__react_component_tooltip.place-top {
  margin-top: -15px;
}

// Dual Image
.dualImage {
  @include pushable();
}
// Dual Image Single
.dualImageSingle {
}

// Loading Indicator
.LoadingIndicator {
  height: 100%;
  width: 100%;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  div {
    width: 100px;
  }
}
.LoadingScreen {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: $whiteColor;
  opacity: 1.0;
  z-index: 100;
  transition: opacity 400ms;

  .LoadingIndicator {
    div {
      width: 150px;
    }
  }
}
.LoadingScreenHidden {
  opacity: 0;
  pointer-events: none;
}

// React Select Box
.react-select-container {
  @include materialThickShadow();
  border-radius: 8px;
  margin: 2px 0px 0px 0px;
  width: 100%;
  text-align: center;

  .react-select__control {
    border-width: 0px;
    border-radius: 8px;
    box-shadow: none;
    background: $offWhiteColor2;

    .react-select__value-container {
      height: 44px;

      .react-select__single-value {
        @include fontSizeNormal();
        color: $darkColor;
        margin-left: 12px;
      }
      .react-select__input {
        color: $darkColor;
        margin-left: 10px;
      }
    }
    .react-select__indicators {

      .react-select__indicator-separator {
        background-color: $offWhiteColor2;
      }
      .react-select__dropdown-indicator {
        color: $darkColor;
      }
    }
  }
  .react-select__menu {
    border-radius: 8px;
    overflow: hidden;
    border-width: 0px;
    background: $offWhiteColor;
    @include materialThickShadow();

    .react-select__group-heading {
      @include fontSizeTiny();
      color: $grayColor2;
      padding: 20px 0px 5px 0px;
    }

    .react-select__menu-list {
      background: $offWhiteColor;

      .react-select__option {
        @include fontSizeNormal();
        background: $offWhiteColor;
        color: $darkColor;
      }
      .react-select__option--is-focused {
        background-color: $grayColor1;
        color: $darkColor;
      }
      .react-select__option--is-selected {
        background-color: $offWhiteColor2;
        color: $darkColor;
      }
    }
  }
}

//Lottie Animation
.LottieAnimation {
  .LottieAnimationView {
    width: 100%;
    height: 100%;
  }
}


// A Switch
.iOSSwitch {

  .react-switch-handle {
    @include materialThickShadow;
  }
  .react-switch-bg {
  }
}
.iOSSwitchUnchecked {
  .react-switch-bg {
  }
}
.iOSSwitchBlock {
  margin-top: 10px;
  @include slideUp();
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;

  .iOSSwitchBlockGroup {

    .details {
      color: $grayBackgroundTextColor;
      font-size: 18px;
      font-weight: 300;
      display: inline-block;
      vertical-align: top;
      margin-top: 6px;
      margin-left: 10px;
    }
  }
}

/* TEMPORARY POPUP AFTER AN ACTION IS PERFORMED */
.toastContainer {
  position: fixed;
  bottom: 45px;
  right: 0;
  left: 0;
  width: 100%;
  text-align: center;
  height: 100px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  pointer-events: none;
}
.actionPopup {
	opacity: 0.0;
	color: $whiteColor;
	background-color: $grayColor2;
	border-radius: 20px;
	font-size: 14px;
	font-weight: 300;
	padding: 4px 15px;
	transition: opacity 1s, transform 1s;
	position: absolute;
	transform: translate(0px, 20px);
}
.actionPopupVisible {
	opacity: 1.0;
	transform: translate(0px, 0px);
}

.inputFilter {
  display: inline-block;
  margin: 4px;

  .inputFilterBox {
    @include fontSizeSmall();
    color: $grayColor;
    padding: 4px 8px;
    background: $offWhiteColor;
    min-width: 100px;
    border-radius: 14px;
    @include pushable();
    cursor: pointer;
    transition: all 0.5s;
  }
}
.inputFilterChanged {
  .inputFilterBox {
    background: $greenLightColor;
    color: $greenColor;
  }
}

.FileSelectorDiv {
  padding: 5px 12px 10px 12px;
  text-align: left;

  .FileSelectorDivImageWrapper {
    display: inline-block;
    vertical-align: middle;
    width: 50px;
    height: 50px;
    overflow: hidden;
    margin-right: 15px;
    object-fit: contain;
    border-radius: 25px;
    padding: 4px;
    background: $whiteColor;
    @include materialThickShadow();
    line-height: 50px;

    .FileSelectorDivImage {
      max-width: 100%;
      max-height: 100%;
      object-fit: contain;
      vertical-align: text-top;
    }
  }

  .FileSelectorUploadInput {
    width: 0.1px;
  	height: 0.1px;
  	opacity: 0;
  	overflow: hidden;
  	position: absolute;
  	z-index: -1;
  }
  .FileSelectorUploadInputLabel {
    width: 100%;
    height: 100%;
    padding: 7px 20px;
    margin: 0px;
    @include fontSizeMedium();
    color: $grayColor2;
    cursor: pointer;
    transition: background-color 0.5s, box-shadow 0.5s, color 0.5s;
    vertical-align: middle;
  }
}

.FullScreenFileSelector {
  position: fixed;
  z-index: 100;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background: transparentize($whiteColor, 0.05);
  overflow: scroll;
  padding: $mainPaddingSize;
  text-align: center;
  @include fadeIn();

  @media (max-width: $breakSmall) {
    padding: $breakPadding;
  }

  .FullScreenFileSelectorFileLoading {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .FullScreenFileSelectorTitle {
    @include fontSizeTitle();
    color: $darkColor;
  }

  .FullScreenFileSelectorDescription {
    @include fontSizeNormal();
    color: $grayColor1;
    padding-top: 20px;
  }

  .FullScreenFileSelectorFileTypes {
    @include fontSizeSmall();
    color: $grayColor1;
    font-weight: 600;
    padding-top: 5px;
  }

  .FullScreenFileUploaderTitle {
    @include fontSizeLarge();
    color: $darkColor;
    padding-top: 30px;
    text-align: center;
  }
  .FullScreenFileUploaderInput {
    width: 0.1px;
  	height: 0.1px;
  	opacity: 0;
  	overflow: hidden;
  	position: absolute;
  	z-index: -1;
  }
  .FullScreenFileUploaderInput + label {
    @include fontSizeNormal();
    color: $whiteColor;
    border-radius: 22px;
    cursor: pointer;
    transition: background-color 0.5s, box-shadow 0.5s, color 0.5s;
    vertical-align: top;

    img {
      padding: 42px;
      width: 100%;
      height: 100%;
      opacity: 1.0;
      @include pushable();
      transition: opacity 0.5s, transform 0.5s;

      &:hover {
        opacity: 0.6;
      }
      &:active {
        opacity: 0.4;
      }
    }
  }
  .FullScreenFileUploaderInput:focus + label,
  .FullScreenFileUploaderInput + label:hover {
    //background-color: lighten($purpleColor, 10%);
  }

  .FullScreenFileSelectorContainer {
    padding-top: 50px;
  }
  .FullScreenFileSelectorFiles {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    .FullScreenFileSelectorFileUpload {
      width: 200px;
      height: 200px;
      cursor: pointer;
      margin: 0px 20px 40px 20px;

      img {
        padding: 42px;
        width: 100%;
        height: 100%;
        opacity: 1.0;
        @include pushable();
        transition: opacity 0.5s, transform 0.5s;

        &:hover {
          opacity: 0.6;
        }
        &:active {
          opacity: 0.4;
        }
      }
    }

    .FullScreenFileSelectorFile {
      width: 200px;
      height: 200px;
      cursor: pointer;
      margin: 0px 20px 40px 20px;
      position: relative;

      .FullScreenFileSelectorFileImage {
        width: 100%;
        height: 100%;
        border-radius: 16px;
        object-fit: cover;
        opacity: 1.0;
        @include pushable();
        transition: opacity 0.5s, transform 0.5s;

        &:hover {
          opacity: 0.6;
        }
        &:active {
          opacity: 0.4;
        }
      }

      .FullScreenFileSelectorFileX {
        position: absolute;
        width: 20px;
        height: 20px;
        top: -10px;
        left: -10px;
        z-index: 100;
      }
    }
  }
}

/* Hide the up/down arrows for inputs where type=number */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance:textfield;
}
