// main: ../master.scss

// The dark themed sidebar for the dashboard
.Instructor {
  @include fadeIn();
  background: $whiteColor;
  height: calc(100% - 100px);

  .InstructorTop {
    height: 100%;
    width: 100%;
    position: relative;

    .InstructorTopBackground {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0px;
      top: 0px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .InstructorTopContent {
      position: absolute;

      .InstructorTopContentName {
        @include fontSizeTitle();
        font-weight: normal;
      }
      .InstructorTopContentTitle {
        @include fontSizeTitle();
      }
      .InstructorTopContentDescription {
        @include fontSizeNormal();
        white-space: pre-line;
        padding-top: 8px;
      }
    }
    // Text Position Options
    .InstructorTopAngleTopLeft {
      top: 50px;
      left: 15%;
    }
    .InstructorTopAngleTopRight {
      top: 50px;
      right: 15%;
    }
    .InstructorTopAngleBottomLeft {
      bottom: 50px;
      left: 15%;
    }
    .InstructorTopAngleBottomRight {
      bottom: 50px;
      right: 15%;
    }
  }

  @media (max-width: $breakSmall) {
    height: calc(100% - 48px);
  }
}

.InstructorBottom {
  width: 100%;

  .InstructorBottomAbout {
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: stretch;

    .InstructorBottomAboutLeft {
      flex-basis: 50%;
      padding: 50px 0px 50px $breakPaddingSize;

      .InstructorBottomAboutLeftTitle {
        @include fontSizeTitle();
        color: $darkColor;
      }
      .InstructorBottomAboutLeftContent {
        .InstructorBottomAboutLeftContentRow {
          @include fontSizeNormal();
          color: $darkColor;
          white-space: pre-line;
        }
        .IBABullets {
          @include fontSizeMedium();
        }
        .IBALocation {
          padding-top: 10px;
          color: $grayColor1;
          font-weight: bold;
        }
        .IBABio {
          color: $grayColor1;
          padding-bottom: 10px;
        }
        .IBALinks {
          color: $darkColor;
          cursor: pointer;
          display: inline-block;
          padding-bottom: 5px;

          img {
            padding-right: 16px;
            height: 30px;
          }
        }
      }
    }
    .InstructorBottomAboutRight {
      position: relative;
      flex-basis: 50%;
      min-height: 500px;

      .InstructorBottomAboutRightImage {
        position: absolute;
        z-index: 10;
        left: 0px;
        top: 0px;
        width: 100%;
        height: 100%;
        object-fit: cover;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }

  .InstructorBottomLive {
    padding: 50px $breakPaddingSize;
    text-align: center;

    .InstructorBottomLiveTitle {
      @include fontSizeTitle();
      color: $darkColor;
      text-align: center;
      padding-bottom: 15px;
    }
  }

  .InstructorBottomOnDemand {
    padding: 50px $breakPaddingSize;
    text-align: center;

    .InstructorBottomOnDemandTitle {
      @include fontSizeTitle();
      color: $darkColor;
      text-align: center;
      padding-bottom: 15px;
    }
  }

  @media (max-width: $breakSmall) {
    .InstructorBottomAbout {
      .InstructorBottomAboutLeft {
        flex-basis: 100%;
      }
      .InstructorBottomAboutRight {
        flex-basis: 100%;
      }
    }
  }
}

.InstructorBottomClass {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  @include fadeIn();

  .InstructorBottomClassLeft {
    padding: $breakPaddingSize;
    flex-basis: 50%;
    text-align: center;
    position: relative;

    .inputBottomLineLightContainer {
      position: absolute;
      z-index: 100;
      left: calc(50% - 90px);
      bottom: 50px;
      width: auto;

      .FileSelectorDiv {
        padding: 0px;
      }
      .inputBottomLineInvalid {
        display: none;
      }
    }

    .InstructorBottomClassLeftImage {
      width: 100%;
      height: 100%;
      max-height: 450px;
      object-fit: cover;
    }

    .InstructorBottomClassLeftButton {
      position: absolute;
      z-index: 100;
      left: calc(50% - 90px);
      bottom: 50px;
    }
  }
  .InstructorBottomClassRight {
    padding: $breakPaddingSize;
    flex-basis: 50%;
    text-align: center;

    .InstructorBottomClassRightContent {
      text-align: center;
      width: 100%;

      .InstructorBottomClassRightContentTitle {
        @include fontSizeTitle();
        color: $darkColor;
        padding-bottom: 10px;
      }
      .InstructorBottomClassRightContentInstructorName {
        @include fontSizeNormal();
        color: $themeColor2;
        padding-bottom: 10px;
        display: inline-block;
        @include pushable();
      }
      .InstructorBottomClassRightContentDescription {
        @include fontSizeMedium();
        color: $darkColor;
        padding-bottom: 10px;
      }
      .InstructorBottomClassRightContentDate {
        @include fontSizeMedium();
        color: $darkColor;
        font-weight: bold;
        padding-bottom: 10px;
      }
      .InstructorBottomClassRightContentLink {
        @include fontSizeMedium();
        color: $themeColor2;
        font-weight: bold;
        padding-top: 10px;
        @include pushable();
        text-decoration: underline;
      }
    }
  }
}

@media (max-width: $breakSmall) {
  .InstructorBottomClass {
    .InstructorBottomClassLeft {
      flex-basis: 100%;
    }
    .InstructorBottomClassRight {
      flex-basis: 100%;
    }
  }
}
