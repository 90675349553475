// main: ../master.scss

// The dark themed sidebar for the dashboard
.Legal {
  padding: $mainPadding;
  @include fadeIn();
  background: $whiteColor;
  min-height: calc(100% - 100px);

  @media (max-width: $breakSmall) {
    padding: $breakPadding;
    min-height: calc(100% - 48px);
  }

  .LegalTitle {
    @include fontSizeTitle();
    color: $darkColor;
  }
  .LegalDate {
    color: $grayColor1;
    @include fontSizeTiny();
    padding-bottom: 20px;
  }
  .LegalSection {
    color: $darkColor;
    @include fontSizeMedium();
    font-weight: 600;
    padding-top: 20px;
  }
  .LegalSectionLarge {
    color: $darkColor;
    @include fontSizeStat();
    font-weight: 600;
    padding-top: 20px;
  }
  .LegalBody {
    color: $darkColor;
    @include fontSizeNormal();
  }
}
