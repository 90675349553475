// main: ../master.scss

.Home {
  @include fadeIn();
  background: $whiteColor;

  .HomeTopSection {
    padding: $mainPadding;
    //background: red;
    position: relative;

    .HomeTopSectionContent {
      position: relative;
      color: $darkColor;
      z-index: 10;
      min-height: 500px;
      text-align: center;

      .HomeTopSectionTitle {
        @include fontSizeHuge();
        font-weight: 600;
        padding-top: 20px;
      }
      .HomeTopSectionDescription {
        @include fontSizeStat();
        padding-top: 8px;
      }
      .HomeTopSectionNoTeams {
        display: flex;
        flex-flow: column wrap;
        justify-content: center;
        align-items: center;
        max-width: 420px;
        margin: 20px auto;
        padding: 20px;

        .HomeTopSectionNoTeamsTitle {
          @include fontSizeStat();
          padding-bottom: 8px;
        }
        .HomeTopSectionNoTeamsDescription {
          @include fontSizeNormal();
        }
      }
      .HomeTopSectionTeams {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        align-items: center;

        .HomeTopSectionTeam {
          background: $offWhiteColor;
          padding: 16px;
          text-align: left;
          width: 334px;
          border-radius: 20px;
          @include boxShadowLight();
          margin: 10px;

          .HomeTopSectionTeamName {
            @include fontSizeMedium();
            font-weight: bold;
            color: $darkColor;
            padding-bottom: 4px;
          }
          .HomeTopSectionTeamLevel {
            @include fontSizeNormal();
            color: $grayColor1;
            padding-bottom: 4px;
          }
          .HomeTopSectionTeamRow {
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
            align-items: center;

            .HomeTopSectionTeamRowEmptyImage {
              height: 50px;
              width: 50px;
              object-fit: contain;
              overflow: hidden;
              border-radius: 25px;
            }
            .HomeTopSectionTeamRowSetImage {
              height: 50px;
              width: 50px;
              object-fit: cover;
              overflow: hidden;
              border-radius: 25px;
              @include boxShadowLight();
            }
          }
          .HomeTopSectionTeamAccessLevel {
            display: flex;
            flex-flow: row nowrap;
            justify-content: flex-start;
            align-items: center;
            padding: 4px 0px;
            .HomeTopSectionTeamAccessLevelTitle {
              @include fontSizeNormal();
              font-weight: bold;
              color: $darkColor;
            }
            .HomeTopSectionTeamAccessLevelValue {
              @include fontSizeNormal();
              font-weight: bold;
              color: $grayColor1;
              padding-left: 16px;
            }
          }
          .HomeTopSectionTeamAccessBar {
            position: relative;
            height: 8px;
            .HomeTopSectionTeamAccessBarBackground {
              position: absolute;
              left: 0px;
              top: 0px;
              width: 100%;
              height: 8px;
              border-radius: 4px;
              background: #E6E6E6;
            }
            .HomeTopSectionTeamAccessBarForeground {
              position: absolute;
              left: 0px;
              top: 0px;
              width: 100%;
              height: 8px;
              border-radius: 4px;
              background: $themeColor1;
            }
          }
        }
      }
    }
  }

  @media (max-width: $breakSmall) {

    .HomeTopSection {
      padding: $breakPadding;
      img {

      }
      .HomeTopSectionContent {

        .HomeTopSectionTitle {
          @include fontSizeTitle();
          font-weight: 600;
        }
        .HomeTopSectionTitleBold {
          @include fontSizeTitle();
          font-weight: 600;
        }
      }
    }
  }

  @media (max-width: 375px) {

    .HomeTopSection {
      img {
      }
      .HomeTopSectionContent {
        .HomeTopSectionTitle {
          font-size: 30px;
        }
        .HomeTopSectionTitleBold {
          font-size: 30px;
        }
      }
    }
  }
}
