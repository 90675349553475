// main: ../master.scss

// The dark themed sidebar for the dashboard
.Instructors {
  padding: $mainPadding;
  @include fadeIn();
  background: $whiteColor;
  min-height: calc(100% - 100px);

  .InstructorsContent {
    .InstructorsTitle {
      @include fontSizeTitle();
      color: $darkColor;
      text-align: center;
    }
    .InstructorsEmpty {
      text-align: center;
      .InstructorsEmptyTitle {
        @include fontSizeNormal();
        color: $darkColor;
        padding-top: 20px;
      }
    }
    .InstructorsList {
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      align-items: center;

      .Instructor {
        margin: 25px;
        @include pushable();

        .InstructorImageContainer {
          width: 150px;
          height: 150px;
          overflow: hidden;
          border-radius: 75px;

          .InstructorImage {
            position: relative;
            width: 100%;
            height: 100%;
            border-radius: 75px;
            left: 0%;
            top: 0%;
            object-fit: cover;
            transition: 0.5s all;

            &:hover {
              -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
              filter: grayscale(100%);
            }
          }
        }
        .InstructorName {
          padding-top: 8px;
          text-align: center;
          color: $darkColor;
          @include fontSizeNormal();
        }
      }
    }
  }

  @media (max-width: $breakSmall) {
    padding: $breakPadding;
    min-height: calc(100% - 48px);
  }
}
