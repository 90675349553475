// main: ../master.scss

.ZoomAuth {
  @include fadeIn();
  background: $whiteColor;
  height: calc(100% - 100px);

  .ZoomAuthTop {
    padding: $mainPadding;
    height: 100%;
    //background: red;
    position: relative;

    //background image
    img {
      position: absolute;
      right: 0px;
      bottom: 0px;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .ZoomAuthTopContent {
      position: absolute;
      top: 50px;
      left: 15%;
      color: $whiteColor;

      .ZoomAuthTopTitle {
        @include fontSizeTitle();
        font-weight: 300;
      }
      .ZoomAuthTopTitleBold {
        @include fontSizeTitle();
      }
      .ZoomAuthTopDescription {
        @include fontSizeNormal();
        padding-top: 8px;
      }
    }
  }

  @media (max-width: $breakSmall) {
    height: calc(100% - 48px);

    .ZoomAuthTop {
      padding: $breakPadding;
      img {
      }
      .ZoomAuthTopContent {
        left: $breakPaddingSize;
      }
    }
  }
}
