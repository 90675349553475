// main: ../master.scss

// The dark themed sidebar for the dashboard
.CreateAccount {
  padding: $mainPadding;
  @include fadeIn();
  background: $offWhiteColor;
  min-height: calc(100% - 100px);

  @media (max-width: $breakSmall) {
    padding: $breakPadding;
    min-height: calc(100% - 48px);
  }
}
