// main: ../master.scss

.Join {
  @include fadeIn();
  background: $whiteColor;
  height: calc(100% - 100px);

  .JoinTopSection {
    padding: $mainPadding;
    height: 100%;
    //background: red;
    position: relative;

    //background image
    img {
      position: absolute;
      right: 0px;
      bottom: 0px;
      height: 100%;
      width: 100%;
      object-fit: cover;
    }

    .JoinTopSectionContent {
      position: absolute;
      top: 50px;
      left: 15%;
      color: $whiteColor;

      .JoinTopSectionTitle {
        @include fontSizeTitle();
        font-weight: 300;
      }
      .JoinTopSectionTitleBold {
        @include fontSizeTitle();
      }
      .JoinTopSectionDescription {
        @include fontSizeNormal();
        padding-top: 8px;
      }
    }
  }

  @media (max-width: $breakSmall) {
    height: calc(100% - 48px);

    .JoinTopSection {
      padding: $breakPadding;
      .JoinTopSectionContent {
        left: $breakPaddingSize;
      }
    }
  }
}

.JoinBottom {
  .JoinSections {

    .JoinRightSection {
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      align-items: center;

      .JoinRightSectionImage {
        flex-basis: 54%;
        flex-grow: 1;
        height: 400px;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .JoinRightSectionData {
        flex-basis: 46%;
        flex-grow: 1;
        color: $darkColor;
        padding: 50px 25px;

        .JoinRightSectionDataContainer {
          text-align: center;
          .JoinRightSectionDataTitle {
            @include fontSizeTitle();
          }
          .JoinRightSectionDataDescription {
            @include fontSizeNormal();
          }
        }
      }
    }

    .JoinLeftSection {
      display: flex;
      flex-flow: row wrap-reverse;
      justify-content: center;
      align-items: center;

      .JoinLeftSectionData {
        flex-basis: 46%;
        flex-grow: 1;
        color: $darkColor;
        padding: 50px 25px;

        .JoinLeftSectionDataContainer {
          text-align: center;
          .JoinLeftSectionDataTitle {
            @include fontSizeTitle();
          }
          .JoinLeftSectionDataDescription {
            @include fontSizeNormal();
          }
        }
      }
      .JoinLeftSectionImage {
        flex-basis: 54%;
        flex-grow: 1;
        height: 400px;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }

  @media (max-width: $breakSmall) {

    .JoinSections {
      .JoinRightSection {
        .JoinRightSectionImage {
          flex-basis: $breakSmall / 2;
        }
        .JoinRightSectionData {
          flex-basis: $breakSmall / 2;
          padding: 50px $breakPaddingSize;
        }
      }
      .JoinLeftSection {
        .JoinLeftSectionImage {
          flex-basis: $breakSmall / 2;
        }
        .JoinLeftSectionData {
          flex-basis: $breakSmall / 2;
          padding: 50px $breakPaddingSize;

          .JoinLeftSectionDataContainer {
            text-align: center;
          }
        }
      }
    }
  }
}
